import type { SeoMetaDefaultConfig } from "~/utils/seo";

export const SEO_META_DEFAULT_CONFIG: SeoMetaDefaultConfig = {
  title: `Powr Connect : Fournisseur en photovoltaïque et panneaux solaires`,
  description: `Découvrez nos solutions photovoltaïques pour professionnels:  modules et panneaux photovoltaïques, onduleurs solaires, systèmes de fixation, batteries et accessoires`,
  siteName: "Powr Connect",
  addSiteNameInTitle: true,
  siteNamePositionInTitle: "end",
  siteNameSeparatorInTitle: " - ",
  cardImagePath: "/og-image.jpg",
  themeColor: "#ffffff",
};

export const categoryRedirections = {
  "kits-residentiels": "kits-solaires",
  modules: "modules-photovoltaiques",
  inverter: "onduleurs-solaires",
  ombrieres: "ombrieres-et-carports",
};

export const searchParamRedirections: { [key: string]: string } = {
  brands: "marques",
  ranges: "gammes",
  filters: "filtres",
  "range-compatible": "gamme-compatible",
  redirectTo: "redirect",
};
